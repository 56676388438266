import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { Popup, Dialog } from 'vant';
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

// 任何跳转之前，先判断用户是否登录
router.beforeEach((to, from, next) => {
    document.title = to.meta.pageTitle;
    if (to.meta.requireAuth) {
        // 判断该路由是否需要登录权限
        if (sessionStorage.getItem("tk")) {
            // 当前的token是否存在
            next();
        } else {
            next({
                name: "Login",
                query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
            });
        }
    } else {
        next();
    }
});
const app = createApp(App);
//performance设置为 true 以在浏览器开发工具的 performance/timeline 面板中启用对组件初始化、编译、渲染和更新的性能追踪。
app.performance = true;
app.config.unwrapInjectedRef = true;
// app.config.errorHandler = (err, vm, info) => {
//     // 处理错误
//     // `info` 是 Vue 特定的错误信息，比如错误所在的生命周期钩子
//     //在这里可以把错误信息传给服务器
// }
app.use(ElementPlus, {
    locale: zhCn,
})
app.use(Dialog);
app.use(Popup);
app.use(router);
app.mount('#app');
